import React from 'react';
import './Cards.css';
import CardItem from './CardItem';

function AboutUs() {
  return (
    <section className="card_page_container">
      <div className='cards__container'>
        <div className='cards__wrapper'>
          <ul className='cards__items'>
            <CardItem
              src='images/kreon.jpg'
              description='Kreon single cover art.'
              youtube='https://youtu.be/5KTgpHIwE-M?si=2YjM6Qztlx0UShcO'
              spotify='https://open.spotify.com/track/4OPgzMDp5JZC4FUg42EVuE?si=001aece1b2da4b2b'
              itunes='https://music.apple.com/hu/song/kreon/1773741302'
              deezer='https://deezer.page.link/ZdVAWPEtJAqnvKBZ6'
            />
            <CardItem
              src='images/emlekszilank_cover.png'
              description='Emlékszilánk single cover art.'
              youtube='https://youtu.be/LktbMLi44C8'
              spotify='https://open.spotify.com/track/5RPt23lgWbFkMfNyJ8mdqK?si=4236c013851249d8'
              itunes='https://music.apple.com/hu/song/eml%C3%A9kszil%C3%A1nk/1773741303'
              deezer='https://deezer.page.link/gyrXR9qiVMEhVgFJA'
            />
            <CardItem
              src='images/ut_vegen_cover.png'
              description='Az út végén single cover art.'
              youtube='https://youtu.be/OGMtWKijTZA?si=TcL2XmM7598g_i5o'
              spotify='https://open.spotify.com/track/53wApxkrqFyvFpVU5N8hI3?si=2aaeba05426541fa'
              itunes='https://music.apple.com/hu/song/az-%C3%BAt-v%C3%A9g%C3%A9n/1773741307'
              deezer='https://deezer.page.link/5VzJ4HREgm8S5Fqd7'
            />
          </ul>
          </div>
        </div>
    </section>
  );
}

export default AboutUs;