import React from 'react';
import AszfSection from './AszfSection/AszfSection';
import AszfNavbar from './AszfNavbar/AszfNavbar';

function Aszf() {
  return (
    <main>
      <AszfNavbar />
      <AszfSection />
    </main>
  );
}

export default Aszf;