import React from 'react'
import './Contact.css'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faEnvelope,
  faPhoneSquareAlt
} from "@fortawesome/free-solid-svg-icons";

function Contact() {
  return (
    <section id='contact' className="contact_section_container">
      <div className="contact_container">
        <h1>KAPCSOLAT</h1>
        <p>MENEDZSMENT</p>
        <div className="subscription_container">        
          <h2><i>Lukács Máté</i></h2>
          <h2>
            <FontAwesomeIcon icon={faEnvelope} color="red"/>
            &nbsp;&nbsp;&nbsp;insomnia.dreamof@gmail.com
          </h2>
          <h2>
            <FontAwesomeIcon icon={faPhoneSquareAlt} color="red"/>
            &nbsp;&nbsp;&nbsp;+3670 703 9101
          </h2>
        </div>
      </div>
    </section>
  )
}

export default Contact
