import React, { useState } from 'react';
import './Navbar.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faFacebookF,
  faInstagram,
  faYoutube,
  faSpotify,
  faTiktok
} from "@fortawesome/free-brands-svg-icons";
import {Link} from 'react-scroll'

function Navbar() {
  const [click, setClick] = useState(false);

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  const toggleHome = () => {
    setClick(false);
  }

  return (
      <header className='navbar-header'>
        <nav className='navbar-container'>
          <Link onClick={toggleHome} activeClass="active" to="hero-container" spy={true} smooth={true} offset={-80} duration={100} className='navbar-logo'>
            <img src='/images/doi_logo.png' alt="Dream of Insomnia's official logo." className="logo-img-size"/>
          </Link>
          <div className='menu-icon' onClick={handleClick}>
            <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
          </div>
          <ul className={click ? 'nav-menu active' : 'nav-menu'}>
            <li className='nav-item'>
              <a 
                activeClass="active" href="https://dreamofinsomnia.bigcartel.com/" spy={true} smooth={true} offset={-80} duration={100}
                exact="true"
                className='nav-links nav-links-merch'
                target="_blank"
                rel="noreferrer"
                onClick={closeMobileMenu}>
                MERCH
              </a>
            </li>
            <li className='nav-item'>
              <Link 
                activeClass="active" to="youtube-hero-container" spy={true} smooth={true} offset={-80} duration={100}
                exact="true"
                className='nav-links' 
                onClick={closeMobileMenu}>
                Premier
              </Link>
            </li>
            <li className='nav-item'>
              <Link
                activeClass="active" to="concerts" spy={true} smooth={true} offset={-80} duration={100}
                exact="true"
                className='nav-links'
                onClick={closeMobileMenu}
              >
                Koncertek
              </Link>
            </li>
            <li className='nav-item'>
              <Link
                activeClass="active" to="contact" spy={true} smooth={true} offset={-80} duration={100}
                exact="true"
                className='nav-links'
                onClick={closeMobileMenu}
              >
                Kapcsolat
              </Link>
            </li>
            <li className='media-sites'>
              <a href='https://www.tiktok.com/@dreamofinsomnia' target='_blank' rel="noreferrer" className="media-sites-icon">
                <FontAwesomeIcon icon={faTiktok} color="white"/>
              </a>
              <a href='https://www.facebook.com/insomnia.dreamof' target='_blank' rel="noreferrer" className="media-sites-icon">
                <FontAwesomeIcon icon={faFacebookF} color="white"/>
              </a>
              <a href='https://www.instagram.com/dreamofinsomnia/' target='_blank' rel="noreferrer" className="media-sites-icon">
                <FontAwesomeIcon icon={faInstagram} color="white"/>
              </a>
              <a href='https://www.youtube.com/c/DreamOfInsomnia' target='_blank' rel="noreferrer"  className="media-sites-icon">
                <FontAwesomeIcon icon={faYoutube} color="white"/>
              </a>
              <a href='https://open.spotify.com/artist/3LWEdVqWhMcHh4KCAukdFB?si=7v-Xg4E3RZSTqNB2vyPOhA' target='_blank' rel="noreferrer" className="media-sites-icon">
                <FontAwesomeIcon icon={faSpotify} color="white"/>
              </a>
            </li>
          </ul>
        </nav>
      </header>
  );
}

export default Navbar;