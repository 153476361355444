import React from 'react';
import './AszfSection.css';

function MainSection() {
  const onButtonClick = () => {
    const pdfUrl = "/files/aszf.pdf";
    const link = document.createElement("a");
    link.href = pdfUrl;
    link.download = "Dream of Insomnia ÁSZF és Adatkezelési Tájékoztató.pdf";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div id='aszf-hero-container'>
      <div className='aszf-hero-container'> 
        <div className="aszf-video-and-title-container">
        <img src='/images/doi_logo.png' alt="Dream of Insomnia's official logo." className='aszf-logo-image'/>
        <div id="aszf-herolink" class="aszf-button" onClick={onButtonClick}>ÁSZF Letöltése</div>
        <a id="aszf-herolink" class="aszf-button" href='/' target="_self">TOVÁBB</a>
        </div>
      </div>
    </div>
  );
}

export default MainSection;