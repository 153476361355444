import React from 'react';
import './MainSection.css';

function MainSection() {
  return (
    <div id='hero-container'>
      <div className='hero-container'> 
        <div className="video-and-title-container">
        <img src='/images/doi_logo.png' alt="Dream of Insomnia's official logo." className='logo-image'/>
        <a id="herolink" class="button" href='https://wmmd.lnk.to/DreamOfInsomnia_Emlekszilank' target="_blank" rel="noreferrer">"EMLÉKSZILÁNK" MEGJELENT</a>
        <a id="herolink" class="button" href='https://dreamofinsomnia.bigcartel.com/' target="_blank" rel="noreferrer">SHOP MERCH</a>
        </div>
      </div>
    </div>
  );
}

export default MainSection;