import React from 'react';
import '../App.css';
import MainSection from './MainSection/MainSection';
import Cards from './CardSection/Cards';
import YoutubeSection from './YoutubeSection/YoutubeSection';
import Concerts from './ConcertSection/Concerts';
import Contact from './ContactSection/Contact';
import Footer from './Footer/Footer';
import Navbar from './Navbar/Navbar';

function Home() {
  return (
    <main>
      <Navbar/>
      <MainSection />
      <Cards />
      <YoutubeSection />
      <Concerts />
      <Contact />
      <Footer />
    </main>
  );
}

export default Home;