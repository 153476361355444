import React from 'react';
import './Footer.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookF,
  faInstagram,
  faYoutube,
  faSpotify,
  faTiktok
} from "@fortawesome/free-brands-svg-icons";

function Footer() {
  return (
    <footer className="footer_section_container">
      <div className="footer_container">
        <div className="footer-nav-item">
          <a href='https://www.tiktok.com/@dreamofinsomnia' target='_blank' rel="noreferrer" className="footer-media-sites-icon">
            <FontAwesomeIcon icon={faTiktok} color="white" size="2x"/>
          </a>
          <a href='https://www.facebook.com/insomnia.dreamof' target='_blank' rel="noreferrer" className="footer-media-sites-icon">
            <FontAwesomeIcon icon={faFacebookF} color="white" size="2x"/>
          </a>
          <a href='https://www.instagram.com/dreamofinsomnia/' target='_blank' rel="noreferrer" className="footer-media-sites-icon">
            <FontAwesomeIcon icon={faInstagram} color="white"  size="2x"/>
          </a>
          <a href='https://www.youtube.com/c/DreamOfInsomnia' target='_blank' rel="noreferrer" className="footer-media-sites-icon">
            <FontAwesomeIcon icon={faYoutube} color="white"  size="2x"/>
          </a>
          <a href='https://open.spotify.com/artist/3LWEdVqWhMcHh4KCAukdFB?si=7v-Xg4E3RZSTqNB2vyPOhA' target='_blank' rel="noreferrer" className="footer-media-sites-icon">
            <FontAwesomeIcon icon={faSpotify} color="white" size="2x"/>
          </a>
        </div>
        <p>© Copyright Dream of Insomnia</p>
      </div>
    </footer>
  )
}

export default Footer;
