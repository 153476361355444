import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faYoutube,
  faSpotify,
  faItunes,
  faDeezer
} from "@fortawesome/free-brands-svg-icons";

function CardItem(props) {

  return (
    <>
      <li className='cards__item'>
        <div className='cards__item__link'>
          <figure className='cards__item__pic-wrap'>
            <img
              className='cards__item__img'
              alt={props.description}
              src={props.src}
            />
          </figure>
          <div className='cards__item__info'>
            <a href={props.youtube} target='_blank' rel="noreferrer" className="card-icon">
              <FontAwesomeIcon icon={faYoutube} color="white" size='2x'/>
            </a>

            <a href={props.spotify} target='_blank' rel="noreferrer" className="card-icon">
              <FontAwesomeIcon icon={faSpotify} color="white" size='2x'/>
            </a>

            <a href={props.itunes} target='_blank' rel="noreferrer" className="card-icon">
              <FontAwesomeIcon icon={faItunes} color="white" size='2x'/>
            </a>

            <a href={props.deezer} target='_blank' rel="noreferrer" className="card-icon">
              <FontAwesomeIcon icon={faDeezer} color="white" size='2x'/>
            </a>
          </div>
        </div>
      </li>
    </>
  );
}

export default CardItem;