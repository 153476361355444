import React from 'react';
import {BrowserRouter as Router, Route} from "react-router-dom";
import './App.css';
import Home from './components/Home';
import AszfDownload from './components/AszfDownload';

function App() {
    return (
      <Router>
        <div className="App">
          <Route exact path="/" component={Home} />
          <Route path="/aszf" component={AszfDownload} />
        </div>
      </Router>
    );
  }


export default App;
