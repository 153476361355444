import React from 'react';
import './Concerts.css'

function Concerts() {
  return (
    <section id='concerts' class="concert_page_container">
      <div class="concerts_container">
        <h1>KONCERTEK</h1>
        <div class='concert-event-container'>      
          <div class='concert-soon-container-root'>
            <div class="concert-soon-container">
              <a class= 'widget-left' href='https://www.facebook.com/events/2259499991055348' target="_blank" rel="noreferrer">XI.08. / DÜRER</a>
            </div>
          </div>
          <div class='ticket-container-root'>
            <div class='tickets-container'>
              <a class= 'widget-right' href='https://www.tixa.hu/dream-of-insomnia-durer' target="_blank" rel="noreferrer">JEGYEK / TIXA</a>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Concerts;
